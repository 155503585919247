.spinner-dialog {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #636363a8;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    .spinner-card {
    width: 150px;
    /* border: 1px solid; */
    text-align: center;
    padding: 20px 40px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px, rgb(34 34 34 / 30%) 0px 1px 18px 0px;
    border-radius: 10px;
    background-color: #FFF;
  
    
    .spinner-animation {
        width: 25px;
    }

    .loading-text {
        margin: 0;
        font-family: 'Inter', sans-serif;;
        font-size: 14px;
        color: #b0a8a8;
        padding: 10px 0;
    }

    .application-brand {
        width: 75px;
    border-radius: 10px;
    }
}
}