.Button_wrapper{
    width: 100%;
    border-radius: 4px !important;
    line-height: 1.75;
    font-size: 15px;
    background: #37578A;
    color: #fff;
    border: unset;
    cursor: pointer;
    margin-bottom: 10px;
    min-width: 64px;
    padding: 6px 16px;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;

}


.primary{
    background-color: #007bff;
  }
  .secondary{
    background-color: #6c757d;
  }
  .success{
    background-color: #28a745;
  }
  .danger{
    background-color: #dc3545;
  }
  .theme-bg {
    background: linear-gradient(
-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.theme-bg2 {
    background: linear-gradient(
-135deg, #899FD4 0%, #A389D4 100%);
}


.btn {
  height: 32px;
  border: transparent;
  border-radius: 5px;
  padding: 0 5px;
  color: #fff;
  min-width: 100px;
  margin: 10px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  &.primary {
    background: #fb6c00;
  }

  &.secondary {
    border: 1px solid #fb6c00;
    background: #fff;
    color: #fb6c00;
  }

  &.disabled {
    background: #626d825e;
    color: #626d82;
    cursor: not-allowed;
  }
}