.login-page-wrapper {
    background-color: rgb(6, 6, 72);
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
}

.login-page-wrapper .login-page {
    margin: 0px auto;
    
    background: #fff;
    
    padding: 20px;
    border-radius: 25px;
    text-align: left;
    padding: 40px;
}

.login-page h3 {
    margin-left: 39px;
}


@media screen and (max-width: 480px) {

    .login-page-wrapper {
        background-color: rgb(6, 6, 72);
        justify-content: center;
        align-items: center;
        height: 70vh;
        display: flex;
    }
    .login-page-wrapper .login-page{
        padding: 20px;
    }
  
  }