




.hide-element {
    display: none;
}



.form-cont {
    margin-bottom: 20px;   
 
 input{
     font-size: 12px;
     line-height: 21px;
     color: #444;
     border: 1px solid #e1e1e1;
     outline: none;
     height: 34px;
     padding: 3px 9px;
     margin: unset !important;
     box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 6%);
     width: 100%;
     border-radius: 4px;
     padding: 8px 10px;
     box-sizing: border-box;
     height: 40px !important;
     display: block;
     
 }
 div{
     margin-top: 5px;
     margin-top: 9px;
     font-size: 13px;
     color: #db4437;
 }
 }
 
 .table-wrp{
     margin-top: 10px;
 }
 
 
 
 
 table {
     text-align: center;
     width: 100%;
     border-radius: 5px;
     margin-top: 5px;
     border-collapse: collapse;
 
     thead,
     tbody {
         tr {
             border-bottom: 1px solid #9e9e9e5c;
 
             th {
                 padding: 12px 15px;
                 overflow: hidden;
                 max-width: 155px;
                 text-align: center;
                 // white-space: nowrap;
                 text-overflow: ellipsis;
                 font-family: "Inter";
                 font-style: normal;
                 font-weight: 400;
                 font-size: 13px;
                 line-height: 20px;
 
             }
 
             td {
                 text-align: center;
                 // white-space: nowrap;
                 text-overflow: ellipsis;
                 font-family: "Inter";
                 font-style: normal;
                 font-weight: 400;
                 font-size: 13px;
                 line-height: 20px;
                 color: #696767;
                 ;
             }
         }
 
         tr:hover {
             background: #9e9e9e17;
         }
     }
 }



 .form-control{
    width: calc(100% - 2px);
    margin: 10px 0;
    height: 37px;
    border-radius: 50px;
    font-family: "Inter", sans-serif;
    outline: none;
    padding: 0 5px;
    background: #FFF;
    border: 1px solid rgba(158, 158, 158, 0.8117647059);
    font-size: 14px;
    text-indent: 9px;
 }

