$color-success: #008000;
$color-warning: #f0a92e;
$color-error: #db4437;

.toaster {
    min-width: 250px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    padding: 5px 15px 5px 5px;
    border-radius: 5px;
    right: 0;
    background: #fff;
    box-shadow: 2px 1px 2px 1px rgb(0 0 0 / 25%);
    margin: 15px;
    cursor: pointer;
    transition: 0.5s;
    &.show {
        top: 0;
    }
    &.hide {
        top: -100px
    }
  .border {
    height: 50px;
    width: 4px;
    border-radius: 5px;
    margin-right: 14px;
    &.success {
      background-color: $color-success;
    }

    &.warning {
      background-color: $color-warning;
    }
    &.error {
      background-color: $color-error;
    }
  }

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    width: 100%;
    .toaster-icon {
      font-size: 20px;
      &.success {
        color: $color-success;
        background-color: #FFF;
      }
      &.warning {
        color: $color-warning;
      }
      &.error {
        color: $color-error;
      }
    }

    p {
      font-family: "Inter", sans-serif;
      margin: 0;
      font-size: 14px;
    }
    .toaster-close-icon {
      color: #626d82;
      font-size: 16px;
    }
  }
}
