.input-wrapper {
  font-size: 12px;
  line-height: 21px;
  color: #444;
  border: 1px solid #a1a1a1;
  outline: none;
  height: 34px;
  padding: 3px 9px;
  margin: unset !important;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 6%);
  width: 100%;
  border-radius: 4px;
  padding: 8px 10px;
  box-sizing: border-box;
  height: 40px !important;
}

.lable-wrapper {
  font-size: 14px;
  color: #746e77;
  margin-bottom: 7px;
}
