@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');


.main-organization-wrp{
    background-color: rgb(6, 6, 72);
    text-align: center;
    text-align: center;
    height: 100vh;
}

.Organization-wrp-title{
    color: #fff;
    padding-top: 9%;
   
  
}

.Organization-wrp-title  h1
{
    font-family: 'Dancing Script', cursive;
    font-size: 58px;
   }

.Organization-wrp{
    
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
}


.inner-organisation-wrp {
    width: 366px;
    background-color: #fff;
    border-radius: 25px;
    text-align: left;
    padding: 40px;
}

.input-wrp div {
    margin-top: 11px;
    margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
    .Organization-wrp {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }

}







